.horizontalScroll {
  display: flex;
  gap: 1rem;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
  padding-bottom: 3rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    height: 0;
  }

  //@media (max-width: 991px) {
  //  display: grid;
  //  grid-template-columns: repeat(2, 1fr);
  //}

  button {
    position: absolute;
    z-index: 2;
    top: calc(50% - 26px);
    border: 0;
    //border-radius: 50%;
    background: white;
    padding: 0.625rem 0.875rem;
  }
}

.smallerButton {
  display: flex;
  gap: 1rem;
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    height: 0;
  }

  //@media (max-width: 991px) {
  //  display: grid;
  //  grid-template-columns: repeat(2, 1fr);
  //}

  button {
    position: absolute;
    z-index: 2;
    top: calc(50% - 12px);
    border: 0;
    //border-radius: 50%;
    background: white;
  }
}



.child {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: 25%;
  @media (max-width: 991px) {
    width: 50%;
  }
}

.childFive {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: calc(20% - 1rem);
  @media (max-width: 991px) {
    width: 50%;
  }
}

.childThree {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  width: calc(40% - 1rem);
  @media (max-width: 991px) {
    width: calc(70% - 1rem);
  }
}

.btnLeft {
  left: 0.5rem;
}

.btnRight {
  right: 0.5rem;
}

.centerItems {
  display: flex;
  justify-content: center;
}
